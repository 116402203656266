/* eslint-disable sort-keys */
/**
 * TODO: add link to figma
 */
export const colors = {
  base: {
    black: '#000000',
    danger: '#FA4D56',
    white: '#FFFFFF',
  },

  blue: {
    100: '#EDF5FF',
    200: '#D0E2FF',
    300: '#A6C8FF',
    400: '#78A9FF',
    450: '#578CFF',
    500: '#4589FF',
    600: '#0F62FE',
    700: '#0043CE',
    800: '#002D9C',
    900: '#001D6C',
    950: '#001141',
  },
  gray: {
    100: '#F4F4F4',
    200: '#E0E0E0',
    300: '#C6C6C6',
    400: '#A8A8A8',
    500: '#8D8D8D',
    600: '#6F6F6F',
    700: '#525252',
    800: '#393939',
    900: '#262626',
    950: '#161616',
  },
  green: {
    100: '#DEFBE6',
    200: '#A7F0BA',
    300: '#6FDC8C',
    400: '#42BE65',
    450: '#05A831',
    500: '#24A148',
    600: '#198038',
    700: '#0E6027',
    800: '#044317',
    900: '#022D0D',
    950: '#071908',
  },
  red: {
    100: '#FF5359',
  },
};

export const numbers = {
  12: 12,
  16: 16,
  2: 2,
  20: 20,
  24: 24,
  32: 32,
  4: 4,
  40: 40,
  6: 6,
  8: 8,
};
